@font-face {
  font-family: 'AmperSans';
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
  font-display: block;
  src: url('/static/fonts/AmperSans/AmperSans-ExtraBold.woff2') format('woff2'),
    url('/static/fonts/AmperSans/AmperSans-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'AmperSans';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: block;
  src: url('/static/fonts/AmperSans/AmperSans-Bold.woff2') format('woff2'),
    url('/static/fonts/AmperSans/AmperSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'AmperSans';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: block;
  src: url('/static/fonts/AmperSans/AmperSans-Medium.woff2') format('woff2'),
    url('/static/fonts/AmperSans/AmperSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'AmperSans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: block;
  src: url('/static/fonts/AmperSans/AmperSans-Regular.woff2') format('woff2'),
    url('/static/fonts/AmperSans/AmperSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'AmperSans';
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
  font-display: block;
  src: url('/static/fonts/AmperSans/AmperSans-Light.woff2') format('woff2'),
    url('/static/fonts/AmperSans/AmperSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'ATArizonaSerif';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: block;
  src: url('/static/fonts/ATArizonaSerif/ATArizonaSerif-Medium.woff') format('woff'),
    url('/static/fonts/ATArizonaSerif/ATArizonaSerif-Medium.woff2') format('woff');
}

@font-face {
  font-family: 'ATArizonaSerif';
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
  font-display: block;
  src: url('/static/fonts/ATArizonaSerif/ATArizonaSerifItalic-Medium.woff') format('woff'),
    url('/static/fonts/ATArizonaSerif/ATArizonaSerifItalic-Medium.woff2') format('woff');
}

@font-face {
  font-family: 'ATArizonaSerif';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: block;
  src: url('/static/fonts/ATArizonaSerif/ATArizonaSerif-Regular.woff2') format('woff2'),
    url('/static/fonts/ATArizonaSerif/ATArizonaSerif-Regular.woff') format('woff');
}

@font-face {
  font-family: 'ATArizonaSerif';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  font-display: block;
  src: url('/static/fonts/ATArizonaSerif/ATArizonaSerifItalic-Regular.woff') format('woff'),
    url('/static/fonts/ATArizonaSerif/ATArizonaSerifItalic-Regular.woff2') format('woff');
}

@font-face {
  font-family: 'EditorialRegular';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: block;
  src: url('/static/fonts/EditorialRegular/EditorialRegular.otf') format('otf'),
    url('/static/fonts/EditorialRegular/EditorialRegular.ttf') format('ttf'),
    url('/static/fonts/EditorialRegular/EditorialRegular.woff') format('woff'),
    url('/static/fonts/EditorialRegular/EditorialRegular.woff2') format('woff2');
}
@font-face {
  font-family: 'EditorialUltralight';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: block;
  src: url('/static/fonts/EditorialUltralight/EditorialUltralight.otf') format('otf'),
    url('/static/fonts/EditorialUltralight/EditorialUltralight.ttf') format('ttf'),
    url('/static/fonts/EditorialUltralight/EditorialUltralight.woff') format('woff'),
    url('/static/fonts/EditorialUltralight/EditorialUltralight.woff2') format('woff2');
}

html {
  font-family: 'AmperSans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
}
